
























import Vue from 'vue'
import Card from '@/components/Card.vue'
import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    Card,
    Button
  },
  props: {
    step: Number,
    next_step_disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['get_width']),
    get_name () {
      return this.step === 0 ? 'Close' : 'Previous step'
    },
    get_next_step_name () {
      return this.step === 5 ? 'Publish campaign' : 'Next Step'
    }
  },
  methods: {
    prevClick () {
      this.$emit('prevClick')
    },
    nextClick () {
      this.$emit('nextClick')
    }
  }
})
