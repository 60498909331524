


































import Vue from 'vue'
import Direction from '@/components/ADSManager/NewCampaign/Direction.vue'
import StepList from '@/components/ADSManager/NewCampaign/StepList.vue'
import StepInfo from '@/components/ADSManager/NewCampaign/StepInfo.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
export default Vue.extend({
  name: 'NewCampaignLayout',
  components: {
    StepList,
    StepInfo,
    Direction
  },
  props: {
    optimize_for: { type: Object },
    step: { required: true, type: Number },
    title: { required: true, type: String },
    subTitle: { required: false, type: String },
    sideInfo: { required: false, default: true, type: Boolean },
    next_step_disabled: {
      type: Boolean,
      default: false
    },
    firstAudience: { required: false, type: Object, default: null },
    audiencesLength: { required: false, type: Number, default: 0 }
  },
  data () {
    return {
      idCampaign: this.$route.params.campaign_id,
      adDesignName: false,
      budget: false,
      audienceTargeting: false
    }
  },

  computed: {
    ...mapState('adsManager/adDesign', ['adDesignServerData']),
    ...mapGetters('budget', ['getCampaigns_budget_data']),
    ...mapGetters('adsManager', ['getTargetingAudiencesData']),
    ...mapGetters(['get_width'])
  },

  methods: {
    ...mapActions('budget', ['getCampaigns_budget']),
    ...mapActions('adsManager', [
      'load_custom_audiences',
      'load_campaign_targeting'
    ]),
    ...mapActions('adsManager/adDesign', ['get_ad_set_info']),
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp']),

    prevClick () {
      this.$emit('prevClick')
    },
    nextClick () {
      this.$emit('nextClick')
    }
  },

  created () {
    if (this.$route.name !== 'NewCampaign') {
      const promises = [
        this.getCampaigns_budget(this.idCampaign),
        this.load_custom_audiences(this.idCampaign),
        this.load_campaign_targeting(this.idCampaign),
        this.get_ad_set_info(this.idCampaign)
      ]
      Promise.allSettled(promises).then(() => {
        this.setStateFieldProp({ field: 'adDesignData', prop: 'instagram_id', value: this.adDesignServerData.data.instagram_id })
        if (this.adDesignServerData?.data?.ad_name_template) {
          this.adDesignName = true
        }
        if (this.getCampaigns_budget_data?.data?.length !== 0) {
          this.budget = true
        }
        if (this.getTargetingAudiencesData?.length > 0) {
          this.audienceTargeting = true
        }
      })
    }
  }
})
