



















































































import Vue from 'vue'
import Card from '@/components/Card.vue'
import LivePreview from '@/components/ADSManager/NewCampaign/AdDesign/LivePreview.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
// import formatters from '@/utils/formatters'

export default Vue.extend({
  props: {
    step: Number,
    firstAudience: { required: false, type: Object, default: null },
    audiencesLength: { required: false, type: Number, default: 0 }
  },
  components: {
    Card,
    // eslint-disable-next-line vue/no-unused-components
    LivePreview,
    BaseAlert
  },
  data () {
    return {
      options: ['Facebook', 'Convertbomb']
    }
  },
  mounted () {
    if (!this.getObjective || !this.getObjective.length) {
      this.load_campaign_objective()
    }
  },
  watch: {
    getABTestingInfo ({ total }) {
      if (total <= 50) {
        this.removeErrorByDetail({ detail: 'Variations count more than 50' })
      }
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignData', 'brand_awareness_optional_fields_toggle']),
    ...mapState('adsManager', ['targeting', 'campaign']),
    ...mapGetters('adsManager/adDesign', ['getABTestingInfo', 'getPlacementType']),
    ...mapGetters('adsManager', ['getCampaign', 'getObjective', 'getObjectiveName', 'getTargetingAudiencesData', 'getLocales', 'getLocalAudiencesData', 'getTargeting']),
    ...mapGetters('budget', ['getCampaigns_budget_data', 'getCurrencySymbol']),
    allowPreview () {
      if (this.adDesignData.creative_format === 'normal' &&
        !!this.campaign && this.campaign.objective !== 'REACH') {
        return (this.adDesignData.headline?.length > 0 &&
          this.adDesignData.call_to_action?.length > 0 &&
          this.adDesignData.images?.length > 0 &&
          this.adDesignData.link_url?.length > 0 &&
          this.adDesignData.page_id)
      }
      if (this.adDesignData.creative_format === 'normal' &&
        !!this.campaign && this.campaign.objective === 'REACH') {
        return this.brand_awareness_optional_fields_toggle
          ? this.adDesignData.images?.length > 0 &&
            this.adDesignData.ad_text?.length > 0 &&
            this.adDesignData.headline?.length > 0 &&
            this.adDesignData.call_to_action?.length > 0 &&
            this.adDesignData.link_url?.length > 0
          : this.adDesignData.images?.length > 0
      }
      if (this.adDesignData.creative_format === 'carousel') {
        return (this.adDesignData.ad_text?.length > 0)
      }
      return false
    },
    campaign_name () {
      return this.getCampaign?.name
    },
    objective () {
      return this.getObjectiveName(this.getCampaign?.objective)
    },
    audience () {
      return this.step === 1 ? this.firstAudience : this.getTargetingAudiencesData[0]
    },
    audience_size () {
      return this.step === 1 ? this.firstAudience.audience_size || 0 : this.audience?.audience_size || 0
    },
    audiences_count () {
      return this.step === 1 ? this.audiencesLength || 0 : this.getTargetingAudiencesData?.length || 0
    },
    including_audiences () {
      return this.audience.audiences.filter(a => a.is_included).map(a => a.name).join('; ')
    },
    excluding_audiences () {
      return this.audience.audiences.filter(a => !a.is_included).map(a => a.name).join('; ')
    },
    location_type () {
      return this.audience?.location_types
    },
    age () {
      return this.audience.age_min + ' - ' + this.audience.age_max
    },
    genders () {
      switch (this.audience.genders) {
        case 0:
          return 'Male and female'
        case 1:
          return 'Male'
        case 2:
          return 'Female'
        default:
          return 'Male and Female'
      }
    },
    interests () {
      return (this.audience.interests) ? this.audience.interests.map(i => i.name).join('; ') : ''
    },
    including_locations () {
      return this.audience.locations.filter(l => l.is_included).map(l => l.name + ', ' + (l.region ? l.region + ', ' : '') + l.country_name).join('; ')
    },
    excluding_locations () {
      return this.audience.locations.filter(l => !l.is_included).map(l => l.name + ', ' + (l.region ? l.region + ', ' : '') + l.country_name).join('; ')
    },
    languages () {
      return (this.audience.locales) ? this.audience.locales.map(locale => this.getLocales.find(l => l.key === locale)?.name).join(', ') : ''
    },
    budget_info () {
      const period = +this.getCampaigns_budget_data.data.budget_type_time === 1 ? 'Total' : 'Daily'
      return `${period} - ${this.getCampaigns_budget_data.data.amount} ${this.getCurrencySymbol}`
    },
    budget_chosen () {
      return this.getCampaigns_budget_data?.data?.amount
    },
    media () {
      return this.adDesignData.media
    }
  },
  methods: {
    ...mapActions('adsManager/adDesign', ['get_live_ad_previews', 'get_ad_format']),
    ...mapActions('adsManager', ['load_campaign_objective']),
    ...mapActions('notifications', ['addNotification']),
    ...mapMutations('adsManager/adDesign', ['removeErrorByDetail']),
    openModal () {
      this.$modal.show(
        LivePreview,
        {
          on_close: this.hide_modal,
          singleAd: false
        },
        {
          width: 900,
          name: 'live-preview-modal',
          adaptive: true,
          height: 'auto',
          overlayTransition: 'linear'
        }
      )
    },
    open_ad_preview_modal () {
      if (this.getPlacementType === 'automatic') {
        this.get_ad_format({ campaignId: this.campaign.id })
          .then(() => {
            this.openModal()
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        if (this.adDesignData.new_ad_placement.devices.length === 0) {
          this.addNotification(
            { body: 'Ads placements request device', type: 'danger' }
          )
        } else if (Object.values(this.adDesignData.new_ad_placement).reduce((prev: number, array: Array<any>): number => prev + array.length, 0) ===
          this.adDesignData.new_ad_placement.devices.length) {
          this.addNotification(
            { body: 'Ads placements request platform', type: 'danger' }
          )
        } else {
          this.get_ad_format({
            campaignId: this.campaign.id,
            data: {
              ad_placements: this.adDesignData.new_ad_placement
            }
          }).then(() => {
            this.openModal()
          })
        }
      }
    }
  }
})
