




































































































































































































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend({
  props: {
    step: Number,
    idCampaign: String,
    adDesignName: Boolean,
    budget: Boolean,
    audienceTargeting: Boolean
  },
  computed: {
    ...mapGetters(['get_sidebar_open_state', 'get_width']),
    ...mapState('newCampaign', ['steps'])
  }
})
