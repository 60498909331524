





















import Vue from 'vue'
export default Vue.extend({
  props: {
    type: {
      type: String,
      // in: ['success', 'info', 'warning', 'danger'],
      default: 'info'
    },
    title: {
      type: String
    },
    message: {
      type: String
    }
  },
  data () {
    return {
      isWithClose: false
    }
  },
  mounted () {
    if (this.$listeners.remove) this.isWithClose = true
  }
})
